<template>
  <div class="wrapper">
    <div
      class="header"
      style="max-width: 600px; margin: 0 auto; width: 100%; position: relative"
    >
      <div class="lang">
        <a @click="setLang">{{ $t($i18n.locale === 'en' ? 'EN' : 'CN') }}</a>
        <img src="../../assets/img/index/ins_icon_down@2x.png" alt="" />
      </div>
      <img src="../../assets/img/index/logo.svg" alt="" />
      <van-popover
        v-model="showPopover"
        placement="bottom-end"
        trigger="click"
        :actions="actions"
        @select="onSelect"
        get-container="#app"
      >
        <template #reference>
          <div class="avatar">
            <img :src="avatar" />
          </div>
        </template>
      </van-popover>
    </div>
    <div class="nologin" v-if="!$store.state.userInfo">
      <div class="title">{{ $t('lang.title') }}</div>
      <div class="tips">{{ $t('lang.noLoginTips') }}</div>
      <div class="login" @click="auth(2)">{{ $t('lang.login') }}</div>
    </div>
    <template v-else>
      <div
        class="content_list"
        style="max-width: 600px; margin: 0 auto; width: 100%"
        v-if="followingList && followingList.length"
      >
        <pull-refresh-list
          :list="list"
          :search="search"
          :getPageData="getPageData"
        >
          <content-item
            :item="item"
            v-for="(item, index) in list"
            :key="index"
          ></content-item>
        </pull-refresh-list>
      </div>
      <div class="nologin" v-else>
        <div class="title">{{ $t('lang.title') }}</div>
        <div class="tips">{{ $t('lang.noFollowed') }}</div>
        <div class="scan" @click="goSquare">{{ $t('lang.viewSquare') }}</div>
      </div>
    </template>
    <van-popup
      v-model="showAppList"
      closeable
      round
      :style="{ 'max-width': '500px', height: '70vh', overflow: 'hidden' }"
    >
      <iframe
        :src="`https://nftonshow.com/app/index?lang=${$i18n.locale}`"
        frameborder="0"
        style="height: 100%"
      />
    </van-popup>
  </div>
</template>

<script>
import { getMetaPhotoBuzzList, getMyFollow } from 'api/api'
import PullRefreshList from 'components/list/PullRefreshList'
import ContentItem from './component/ContentItem'
import { Popover, Popup } from 'vant'

export default {
  name: 'Index',
  data() {
    return {
      list: [],
      search: {
        metaId: '',
        timestamp: 0
      },
      followData: {},
      avatar: '',
      followingList: [],
      showPopover: false,
      actions: [
        { text: this.$t('lang.personalCenter') },
        { text: this.$t('lang.appCenter') },
        { text: this.$t('lang.about') },
        { text: this.$t('lang.logout') }
      ],
      showAppList: false
    }
  },
  created() {
    // debugger
    this.avatar = `${
      process.env.VUE_APP_AppImgApi
    }/metafile/avatar/${localStorage.getItem('showId')}` // 未登录取默认头像
  },
  mounted() {
    if (
      !this.$store.state.userInfo ||
      (this.$store.state.isIOS && this.$store.state.PhoneShow) ||
      (this.$store.state.isAndroid && this.$store.state.PhoneShow)
    ) {
      this.actions.splice(3, 1)
    }
    // debugger
    let followData = localStorage.getItem('followData')
    if (!followData) {
      return
    }
    this.followingList = JSON.parse(followData).followingList
  },
  components: {
    PullRefreshList,
    ContentItem,
    [Popover.name]: Popover,
    [Popup.name]: Popup
  },
  methods: {
    getPageData(params) {
      params.metaId = localStorage.getItem('showId')
      return getMetaPhotoBuzzList(params).then((res) => {
        let followData = localStorage.getItem('followData')
        let data = {
          results: {
            items: res.data.results.items
              .map((res) => {
                return {
                  ...res,
                  isFollow: followData
                    ? (JSON.parse(followData).followingList
                        ? JSON.parse(followData).followingList
                        : []
                      ).includes(res.metaId)
                    : false
                }
              })
              .filter((res) => res.metaId !== 'NUll') // 临时过滤
          },
          total: res.data.total
        }
        return data
      })
    },
    auth(appType) {
      debugger
      if (this.$store.state.userInfo) {
        this.$router.push({
          name: 'User2',
          params: {
            txId: this.$store.state.userInfo.metaId
          }
        })
      } else {
        if (this.$store.state.userInfoLoading) return
        this.$store.state.sdk?.changeSdkType(appType)
        this.$store.state.sdk?.login()
      }
    },
    setLang() {
      const lang = this.$i18n.locale === 'en' ? 'zh' : 'en'
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
    },
    goSquare() {
      this.$router.push({
        path: '/square'
      })
    },
    onSelect(action) {
      if (action.text === this.$t('lang.personalCenter')) {
        this.auth(2)
      } else if (action.text === this.$t('lang.appCenter')) {
        this.showAppList = true
      } else if (action.text === this.$t('lang.about')) {
        this.$router.push('/about')
      } else if (action.text === this.$t('lang.logout')) {
        this.$dialog
          .confirm({
            title: '提示',
            message: '确认退出吗？'
          })
          .then(() => {
            // on confirm
            this.actions.splice(3, 1)
            this.$store.commit('LOGOUT')
            this.$store.commit('SETSDK')
          })
          .catch(() => {
            // on cancel
          })
      }
    }
  },
  watch: {
    $route(to, from) {
      debugger
    },
    '$i18n.locale'() {
      this.actions = [
        { text: this.$t('lang.personalCenter') },
        { text: this.$t('lang.appCenter') },
        { text: this.$t('lang.about') },
        { text: this.$t('lang.logout') }
      ]
    },
    '$store.state.userInfo'() {
      this.avatar = `${
          process.env.VUE_APP_AppImgApi
      }/metafile/avatar/${localStorage.getItem('showId')}` // 未登录取默认头像

      getMyFollow({
        metaId: localStorage.getItem('showId')
      }).then(res => {
        localStorage.setItem('followData', JSON.stringify(res.data))
        let followData = localStorage.getItem('followData')
        this.followingList = JSON.parse(followData).followingList
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 0.5px solid #c4c5c6;
    .lang {
      position: absolute;
      left: 0;
      margin-left: 18px;
      cursor: pointer;
      img {
        width: 9px;
        height: 6px;
        margin-left: 3px;
      }
    }
    > img {
      width: 183px;
      height: 25px;
    }
    /deep/ .van-popover__wrapper {
      height: 36px;
      position: absolute;
      right: 0;
      margin-right: 17px;
    }
    .avatar {
      //position: absolute;
      //right: 0;
      //margin-right: 17px;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .nologin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      color: #303133;
      margin-top: 168px;
    }
    .tips {
      font-size: 14px;
      width: 293px;
      height: 28px;
      line-height: 20px;
      text-align: center;
      color: #bfc2cc;
      font-size: 14px;
      margin-top: 15px;
    }
    .scan {
      background-color: #617fff;
      width: 4rem;
      height: 0.8rem;
      border-radius: 0.53333rem;
      font-size: 0.32rem;
      color: #ffffff;
      text-align: center;
      line-height: 0.8rem;
      margin-top: 0.8rem;
      cursor: pointer;
    }
    .login {
      background-color: #617fff;
      width: 4rem;
      height: 0.8rem;
      border-radius: 0.53333rem;
      font-size: 0.32rem;
      color: #ffffff;
      text-align: center;
      line-height: 0.8rem;
      margin-top: 0.8rem;
      cursor: pointer;
    }
  }
  .content_list {
    overflow: auto;
    position: absolute;
    top: 60px;
    bottom: 50px;
    left: 0;
    right: 0;
  }
}
</style>
