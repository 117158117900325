var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "header",
          staticStyle: {
            "max-width": "600px",
            margin: "0 auto",
            width: "100%",
            position: "relative",
          },
        },
        [
          _c("div", { staticClass: "lang" }, [
            _c("a", { on: { click: _vm.setLang } }, [
              _vm._v(_vm._s(_vm.$t(_vm.$i18n.locale === "en" ? "EN" : "CN"))),
            ]),
            _c("img", {
              attrs: {
                src: require("../../assets/img/index/ins_icon_down@2x.png"),
                alt: "",
              },
            }),
          ]),
          _c("img", {
            attrs: { src: require("../../assets/img/index/logo.svg"), alt: "" },
          }),
          _c("van-popover", {
            attrs: {
              placement: "bottom-end",
              trigger: "click",
              actions: _vm.actions,
              "get-container": "#app",
            },
            on: { select: _vm.onSelect },
            scopedSlots: _vm._u([
              {
                key: "reference",
                fn: function () {
                  return [
                    _c("div", { staticClass: "avatar" }, [
                      _c("img", { attrs: { src: _vm.avatar } }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.showPopover,
              callback: function ($$v) {
                _vm.showPopover = $$v
              },
              expression: "showPopover",
            },
          }),
        ],
        1
      ),
      !_vm.$store.state.userInfo
        ? _c("div", { staticClass: "nologin" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("lang.title"))),
            ]),
            _c("div", { staticClass: "tips" }, [
              _vm._v(_vm._s(_vm.$t("lang.noLoginTips"))),
            ]),
            _c(
              "div",
              {
                staticClass: "login",
                on: {
                  click: function ($event) {
                    return _vm.auth(2)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.login")))]
            ),
          ])
        : [
            _vm.followingList && _vm.followingList.length
              ? _c(
                  "div",
                  {
                    staticClass: "content_list",
                    staticStyle: {
                      "max-width": "600px",
                      margin: "0 auto",
                      width: "100%",
                    },
                  },
                  [
                    _c(
                      "pull-refresh-list",
                      {
                        attrs: {
                          list: _vm.list,
                          search: _vm.search,
                          getPageData: _vm.getPageData,
                        },
                      },
                      _vm._l(_vm.list, function (item, index) {
                        return _c("content-item", {
                          key: index,
                          attrs: { item: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "nologin" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lang.title"))),
                  ]),
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(_vm._s(_vm.$t("lang.noFollowed"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scan", on: { click: _vm.goSquare } },
                    [_vm._v(_vm._s(_vm.$t("lang.viewSquare")))]
                  ),
                ]),
          ],
      _c(
        "van-popup",
        {
          style: { "max-width": "500px", height: "70vh", overflow: "hidden" },
          attrs: { closeable: "", round: "" },
          model: {
            value: _vm.showAppList,
            callback: function ($$v) {
              _vm.showAppList = $$v
            },
            expression: "showAppList",
          },
        },
        [
          _c("iframe", {
            staticStyle: { height: "100%" },
            attrs: {
              src: "https://nftonshow.com/app/index?lang=" + _vm.$i18n.locale,
              frameborder: "0",
            },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }